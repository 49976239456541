.btnStyle {
  width: fit-content;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  gap: 8px;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 10px 24px;
  border-radius: 24px;
  box-shadow: 0px 4px 16px 0px rgba(19, 115, 251, 0.239);
  position: relative;
  overflow: hidden;
}
.primaryBtnStyle {
  background-color: var(--primary-color);
  color: var(--white);
  box-shadow: 0px 4px 16px 0px #1373fb3d;
}
.whiteBtnStyle {
  background-color: var(--white);
  color: #000000;
  box-shadow: 0px 4px 16px 0px var(--border-color);
  border: 1px solid #505050;
}
.grayBtnStyle {
  background: var(--bg-secondary-color);
  color: #000000;
  border: 1px solid var(--border-color);
}
.loaderStyle {
  width: 25px;
  height: 25px;
}
.disabledStyle {
  cursor: default;
  opacity: 0.5;
}
.largeBtnStyle {
  padding: 10px 24px;
}
.mediumBtnStyle {
  padding: 8px 24px;
}
.smallBtnStyle {
  padding: 6px 24px;
}
