.containerStyle {
  display: flex;
  flex-direction: column;
  max-width: 660px;
  width: 100%;
  height: fit-content;
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px 0px;
  gap: 24px;
}
.headerViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 24px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--black);
}
.middleSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tabSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 0px 24px 24px 24px;
  border-bottom: 1px solid var(--border-color);
}
.tabTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--dark-gray-color);
  cursor: pointer;
}
.activeTabStyle {
  font-family: 'Euclid-Circular-B-Medium';
  color: var(--primary-color);
  font-weight: 500;
  position: relative;
}
.activeTabStyle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--primary-color);
  bottom: -25px;
  left: 0;
  border-radius: 1px;
}
.profileSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0px 24px;
}
.profileImgStyle {
  width: 96px;
  height: 96px;
  font-size: 48px;
}
.avataImgStyle {
  object-fit: cover;
  border-radius: 50%;
}
.labelAndUploadViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--dark-gray-color);
}
.uploadBtnStyle {
  padding: 8px 24px;
  border-radius: 24px;
  background-color: var(--bg-primary-color);
  box-shadow: none;
  color: var(--black);
}
.uploadAndDeleteSectinStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.deleteIconStyle {
  cursor: pointer;
}
.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 24px;
}
.inputSubContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.customLabelTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.btnSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.btnStyle {
  height: 40px;
  padding: 0px 24px;
}
.errorTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  color: var(--pink-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@media screen and (max-width: 867px) {
  .containerStyle {
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .containerStyle {
    max-width: 100%;
    border-radius: 0px;
    height: 100%;
  }
}
@media screen and (max-width: 374px) {
  .headerViewStyle,
  .profileSectionStyle {
    padding: 0px 20px;
  }
  .headerTextStyle {
    font-size: 18px;
    line-height: 28px;
  }
  .tabSectionStyle {
    padding: 0px 20px 20px 20px;
  }
  .activeTabStyle::after {
    bottom: -20px;
  }
  .profileImgStyle {
    width: 80px;
    height: 80px;
    font-size: 38px;
  }
  .uploadBtnStyle {
    font-size: 13px;
    padding: 8px 20px;
  }
  .btnStyle,
  .inputContainerStyle {
    padding: 0px 20px;
  }
  .labelTextStyle,
  .customLabelTextStyle {
    font-size: 12px;
  }
}
