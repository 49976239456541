:root {
  --navbar-height: 64px;
}
.containerStyle {
  background-color: rgb(18 19 21);
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}
.navContainerLightStyle {
  background-color: var(--white);
}
.navSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 80px;
  max-width: 1440px;
  width: 100%;
  height: 100%;
}
.middleSectionStyle {
  display: flex;
  align-items: center;
  gap: 48px;
}
.navLinkStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--off-white-color);
  text-decoration: none;
}
.rightSectionStyle {
  display: flex;
  align-items: center;
  gap: 24px;
}
.navIconStyle {
  width: 26px;
  height: 26px;
}
.menuModalStyle {
  position: fixed;
  top: var(--navbar-height);
  width: 100%;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid var(--off-white-color);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 32px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.menuItemTitleWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.menuLinkBlueStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  text-decoration: none;
}
.menuLinkStyle,
.menuItemTitleWrapperStyle span {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  text-decoration: none;
}
.activeLinkStyle {
  position: relative;
}
.activeLinkStyle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--white);
  bottom: -4px;
  left: 0px;
}
.menuItemBlockStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profileMenuModalStyle {
  width: 100%;
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.profileMenuHeaderStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: var(--navbar-height);
  border-bottom: 1px solid var(--off-white-color);
}
.userMenuStyle {
  /* padding: 24px 32px; */
}

.profileModalStyle {
  width: 320px;
  height: auto;
  top: 21px !important;
  box-shadow: 4px 8px 24px 0px var(--black-color08);
  border: 1px solid var(--off-white-color);
}
.signInUpBlockStyle {
  display: flex;
  flex-direction: column;
}
.signInUpHeaderStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid var(--off-white-color);
}
.signInUpHeaderTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.signInUpBodyStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  align-items: center;
}
.signInUpBodyIconStyle {
  width: 64px;
  height: 64px;
}
.signInUpTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--black);
}
.signInUpBtnWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.signInUpBtnStyle {
  border-radius: 20px;
  height: 40px;
  flex: 1;
  width: 100%;
}

.langModalStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  top: 21px !important;
  width: 254px;
  border-radius: 16px;
}
.langItemStyle {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.langItemTitleStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-gray-color);
  cursor: pointer;
}
.selectedLangItemTitleStyle {
  color: var(--primary-color);
}
.cartModalStyle {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0 !important;
  left: unset !important;
  right: 0 !important;
  width: 588px;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
}
@media screen and (max-width: 1440px) {
  .navLinkStyle {
    font-size: 15px;
  }
  .navIconStyle {
    width: 24px;
    height: 24px;
  }
  .cartModalStyle {
    width: 452px;
  }
}
@media screen and (max-width: 1366px) {
  .navLinkStyle {
    font-size: 14px;
  }
}
@media screen and (max-width: 1023px) {
  .navSectionStyle {
    padding: 0 40px;
  }
}
@media screen and (max-width: 867px) {
  :root {
    --navbar-height: 56px;
  }
  .navSectionStyle {
    padding: 0 32px;
  }
  .cartModalStyle {
    width: 390px;
  }
}
@media screen and (max-width: 559px) {
  .navSectionStyle {
    padding: 0 24px;
  }
  .menuModalStyle {
    padding: 16px 24px;
  }
  .profileMenuHeaderStyle {
    padding: 0 24px;
  }
  .userMenuStyle {
    /* padding: 24px; */
    gap: 12px;
  }
  .cartModalStyle {
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: 0 !important;
    width: 100%;
    max-height: calc(100% - 64px);
    border-radius: 16px 16px 0px 0px;
  }
}
@media screen and (max-width: 374px) {
  .navSectionStyle {
    padding: 0 20px;
  }
  .menuModalStyle {
    gap: 10px;
    padding: 16px 20px;
  }
  .menuLinkBlueStyle,
  .menuLinkStyle,
  .menuItemTitleWrapperStyle span {
    font-size: 14px;
    line-height: 22px;
  }
  .profileMenuHeaderStyle {
    padding: 0 20px;
  }
  .userMenuStyle {
    /* padding: 20px; */
    gap: 10px;
  }
}
