.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--bg-secondary-color);
}
.subContainerStyle {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  height: calc(100vh - 64px);
  margin: auto;
  padding: 24px;
  gap: 4px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  text-align: left;
  color: var(--black);
}
/* left section styles  */
.leftSectionStyle {
  max-width: 390px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  overflow: hidden;
  flex-shrink: 0;
}
.leftSectionHeaderBlockStyle {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}
.leftSectionHeaderTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
.itemsWrapperStyle {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
}
.selectedItemStyle {
  background-color: var(--pale-blue-color);
  border-radius: 8px;
}
.eachItemStyle {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
}
.eachItemStyle:hover {
  background-color: var(--pale-blue-color);
}
.itemCategoryIconStyle {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: var(--bg-secondary-color);
  /* padding: 16px; */
  flex-shrink: 0;
  overflow: hidden;
}
.productDataBlockStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.productTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 17px;
  line-height: 26px;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;
}
.productDescStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  white-space: nowrap;
}
.productDescStyle span {
  color: var(--medium-gray-color);
  margin-left: 4px;
}
/* right section style start  */
.rightSectionStyle {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  overflow: hidden;
  overflow-y: scroll;
}
.itemImgSectionStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 244px;
  position: relative;
}
.itemImgStyle {
  width: 100%;
  height: 100%;
}
.imgStyle {
  object-fit: cover;
}
.rightHeaderBtnsWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 24px 24px 0px 24px;
}
.rightHeaderbtnStyle {
  width: 40px;
  height: 40px;
  background-color: rgba(17, 17, 17, 0.56);
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}
.iconStyle {
  width: 18px;
  height: 18px;
}
.optionsModalStyle {
  width: 216px;
  border-radius: 8px;
  border: 1px solid var(--off-white-color);
  box-shadow: 4px 8px 24px 0px rgba(0, 0, 0, 0.08);
  top: -26px !important;
  padding: 8px;
}
.eachOptionStyle {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  gap: 8px;
  border-radius: 8px;
}
.eachOptionStyle:hover {
  background-color: var(--bg-primary-color);
}
.optionTitleStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-gray-color);
}
.itemWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}
.labelTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: var(--medium-gray-color);
}
.itemDetailsWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.itemDataStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.titleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 26px;
  color: var(--black);
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--medium-gray-color);
}
.priceSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.offerPriceTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--black);
}
.originalPriceTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--medium-gray-color);
  text-decoration: line-through;
}
.btnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
}
.btnStyle {
  width: 100%;
}

/* empty section style  */
.emptyItemContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
}
.emptyContainerTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--dark-gray-color);
  text-align: center;
  max-width: 54%;
}
.successMsgStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--primary-color);
}
.errorMsgStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--pink-olor);
}

@media screen and (max-width: 1440px) {
  .leftSectionHeaderTitleStyle {
    font-size: 20px;
  }
  .productTitleStyle {
    font-size: 16px;
  }
}
@media screen and (max-width: 1336px) {
  .leftSectionHeaderTitleStyle {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1279px) {
  .leftSectionStyle {
    max-width: 360px;
  }
}
@media screen and (max-width: 1023px) {
  .leftSectionStyle {
    max-width: 320px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 20px;
  }
  .itemCategoryIconStyle {
    width: 48px;
    height: 48px;
  }
  .productTitleStyle {
    font-size: 15px;
  }
}
@media screen and (max-width: 867px) {
  .leftSectionStyle {
    max-width: 100%;
    border-radius: 0px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 32px;
  }
  .eachItemStyle {
    padding: 8px 24px;
  }
  .productTitleStyle {
    font-size: 16px;
  }
  .rightSectionStyle {
    position: fixed;
    top: 64px;
    left: 0;
    border-radius: 0px;
  }
}
@media screen and (max-width: 576px) {
  .headerTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 20px;
  }
  .leftSectionHeaderTitleStyle {
    font-size: 18px;
  }
  .eachItemStyle {
    padding: 12px 16px;
  }
  .titleTextStyle {
    font-size: 24px;
  }
  .descTextStyle {
    font-size: 15px;
    line-height: 20px;
  }
}
@media screen and (max-width: 374px) {
  .headerTextStyle {
    font-size: 26px;
    line-height: 30px;
  }
  .productTitleStyle {
    font-size: 14px;
  }
}
