.containerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 100px 160px;
}
.headerViewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  color: var(--black);
}
.headerDescTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
  color: var(--black);
}
.eachCardSectionStyle,
.eachCardSectionStyle2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 128px;
}
.eachSectionLeftViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.eachSectionTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
  color: var(--black);
}
.eachSectionDescTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--black);
}
.eachSectionImgStyle {
  width: 440px;
  height: 440px;
}
.appStoreWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
}
.storeIocnStyle {
  cursor: pointer;
}
@media screen and (max-width: 1279px) {
  .containerStyle {
    padding: 80px;
    gap: 100px;
  }
  .headerDescTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .eachCardSectionStyle,
  .eachCardSectionStyle2 {
    gap: 100px;
  }
  .eachSectionImgStyle {
    width: 320px;
    height: auto;
  }
}
@media screen and (max-width: 1023px) {
  .containerStyle {
    padding: 80px 60px;
    gap: 56px;
  }
  .headerViewStyle {
    gap: 12px;
  }
  .headerTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .eachCardSectionStyle,
  .eachCardSectionStyle2 {
    gap: 56px;
  }
  .eachSectionTitleStyle {
    font-size: 26px;
    line-height: 34px;
  }
}
@media screen and (max-width: 867px) {
  .containerStyle {
    padding: 80px 32px;
    gap: 56px;
  }
  .headerTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .eachCardSectionStyle {
    width: 100%;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .eachCardSectionStyle2 {
    width: 100%;
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .eachSectionTitleStyle {
    text-align: center;
  }
  .eachSectionDescTextStyle {
    text-align: center;
  }
  .eachSectionImgStyle {
    width: 452px;
    height: 452px;
  }
  .eachSectionTitleStyle {
    font-size: 24px;
    line-height: 34px;
  }
}
@media screen and (max-width: 576px) {
  .containerStyle {
    padding: 64px 24px;
    gap: 34px;
  }
  .headerViewStyle {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .headerDescTextStyle {
    text-align: left;
  }
  .eachSectionTitleStyle {
    text-align: left;
  }
  .eachSectionDescTextStyle {
    text-align: left;
  }
  .eachSectionImgStyle {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 374px) {
  .containerStyle {
    padding: 56px 20px;
    gap: 56px;
  }
  .headerViewStyle {
    gap: 6px;
  }
  .headerTextStyle {
    font-size: 26px;
    line-height: 36px;
  }
  .headerDescTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .eachSectionTitleStyle {
    font-size: 18px;
    line-height: 26px;
  }
}
