.containerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 780px;
  width: 100%;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--medium-gray-color);
}
.ordersListStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow: scroll;
}
.productContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.productDesktopViewStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.avatarContainerStyle,
.imgStyle {
  width: 102px;
  height: 102px;
  border-radius: 8px;
}
.productDetailsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.detailsWrapperStyle {
  display: flex;
  flex-direction: column;
}
.productNameStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--black);
}
.productPriceStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--desc-color);
}
.priceTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-left: 4px;
  color: var(--black);
}
.btnDesktopStyle {
  box-shadow: none;
  border: none;
}
.btnDskDisabledStyle {
  box-shadow: none;
}
.btnMobileStyle {
  box-shadow: none;
  border: none;
  display: none;
}
.btnMblDisabledStyle {
  box-shadow: none;
  display: none;
}
.orderWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px;
}
.orderDetailsWrapperStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.orderStatusWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.orderNoTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--black);
}
.orderDateWrapperStyle {
  display: flex;
  flex-direction: column;
}
.orderDateTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--dark-gray-color);
}
.orderStatusTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--dark-gray-color);
}
.statusColorTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--primary-color);
}
.orderPriceWrapperStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orderPriceTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: right;
  color: var(--black);
}
.orderInvoiceTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--dark-gray-color);
  text-decoration: underline;
  cursor: pointer;
}
.productsListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.btnsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.cancelBtnStyle {
  box-shadow: none;
  border: 1px solid var(--light-gray-color);
}
.trackBtnStyle {
  box-shadow: none;
}
/* track order modal styles */
.orderTrackModalStyle {
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 454px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.overlayStyle {
  background-color: var(--black-color25);
  backdrop-filter: blur(1px);
  z-index: 1;
}
.modalHeaderWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalHeaderTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--black);
}
.deliveryDateTextStyle {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--desc-color);
}
.statusStepsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.iconWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 32px;
  background-color: var(--primary-color);
}
.iconStyle {
  width: 28px;
  height: 28px;
}
.stepsTextWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.stepsHeaderTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--black);
}
.timeZoneWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 3px;
}
.stepsTimeTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--dark-gray-color);
}
/* review modal styles  */
.orderReviewModalStyle {
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 454px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.reviewModalWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.reviewHeaderTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: var(--black);
}
.reviewBtnsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.cancelReviewBtnStyle {
  box-shadow: none;
  border: none;
}
.submitBtnStyle {
}
@media screen and (max-width: 1440px) {
  .headerTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .avatarContainerStyle,
  .imgStyle {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    font-size: 38px;
  }
  .productNameStyle,
  .orderDateTextStyle,
  .orderStatusTextStyle,
  .statusColorTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .orderNoTextStyle,
  .orderPriceTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .orderInvoiceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  /* track order modal styles  */
  .modalHeaderTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .deliveryDateTextStyle,
  .stepsHeaderTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .productPriceStyle,
  .priceTextStyle,
  .stepsTimeTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .iconWrapperStyle {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  .iconStyle {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 1336px) {
}
@media screen and (max-width: 1279px) {
}
@media screen and (max-width: 1023px) {
}
@media screen and (max-width: 867px) {
  .ordersListStyle {
    height: calc(100vh - 100px);
  }
}
@media screen and (max-width: 576px) {
  .containerStyle {
    padding: 24px;
  }
  .productDesktopViewStyle {
    align-items: center;
  }
  .avatarContainerStyle,
  .imgStyle {
    width: 80px;
    height: 80px;
    font-size: 28px;
  }
  .btnDesktopStyle {
    display: none;
  }
  .btnDskDisabledStyle {
    display: none;
  }
  .btnMobileStyle {
    box-shadow: none;
    border: none;
    display: block;
    width: 100%;
    text-align: center;
  }
  .btnMblDisabledStyle {
    box-shadow: none;
    display: block;
    width: 100%;
    text-align: center;
  }
  .orderWrapperStyle {
    gap: 20px;
    padding: 20px;
  }
  .orderDetailsWrapperStyle {
    display: flex;
    flex-direction: column;
  }
  .orderPriceWrapperStyle {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .btnsWrapperStyle {
    flex-direction: column;
    gap: 8px;
    border-top: 1px solid var(--light-gray-color);
    padding-top: 20px;
  }
  .cancelBtnStyle {
    width: 100%;
  }
  .trackBtnStyle {
    width: 100%;
  }
  .ordersListStyle {
    height: calc(100vh - 120px);
  }
  /* track order modal styles */
  .orderTrackModalStyle {
    top: 40px;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  /* review modal styles  */
  .orderReviewModalStyle {
    top: 40px;
    max-width: 100%;
    width: 100%;
    height: calc(100% - 40px);
    justify-content: space-between;
    border-radius: 16px 16px 0px 0px;
  }
  .reviewHeaderTextStyle {
    font-size: 22px;
    line-height: 32px;
  }
  .reviewModalWrapperStyle {
    gap: 20px;
  }
}
@media screen and (max-width: 374px) {
  .productDesktopViewStyle {
    gap: 10px;
  }
  .avatarContainerStyle,
  .imgStyle {
    width: 64px;
    height: 64px;
    font-size: 20px;
  }
  .productNameStyle,
  .orderDateTextStyle,
  .orderStatusTextStyle,
  .statusColorTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .productPriceStyle,
  .priceTextStyle {
    font-size: 12px;
    line-height: 20px;
  }
  .orderWrapperStyle {
    gap: 16px;
    padding: 16px;
  }
  .orderStatusWrapperStyle {
    gap: 2px;
  }
  .orderNoTextStyle,
  .orderPriceTextStyle {
    font-size: 18px;
    line-height: 28px;
  }
  .orderInvoiceTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .btnsWrapperStyle {
    gap: 8px;
    padding-top: 16px;
  }
  /* track order modal styles */
  .orderTrackModalStyle {
    gap: 20px;
    padding: 20px;
  }
  .modalHeaderTextStyle {
    font-size: 18px;
    line-height: 28px;
  }
  .deliveryDateTextStyle {
    padding: 12px;
    font-size: 14px;
    line-height: 22px;
  }
  .statusStepsWrapperStyle {
    gap: 12px;
  }
  .iconWrapperStyle {
    min-width: 44px;
    width: 44px;
    height: 44px;
  }
  .stepsHeaderTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .stepsTimeTextStyle {
    font-size: 13px;
    line-height: 20px;
  }
  /* review modal styles  */
  .orderReviewModalStyle {
    gap: 20px;
    padding: 20px;
  }
  .reviewHeaderTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .reviewModalWrapperStyle {
    gap: 16px;
  }
}
