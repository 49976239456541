.containerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 128px;
  gap: 64px;
}
.simpleHeaderContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simpleHeaderTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
}
.simpleDescriptionTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
  margin-top: 16px;
  text-align: center;
  width: 738px;
}
.questionsContainerStyle {
  padding: 0px 140px;
}
.questionWrapperStyle {
  margin-bottom: 40px;
}
.questionTextwrapperStyle {
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}
.questionTextStyle {
  max-width: 684px;
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  text-align: left;
}
.arrowImgWrapperStyle {
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
}
.answerTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #202020;
  padding: 0px 32px;
  text-align: left;
  margin-top: 16px;
}
.letsTalkWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.letsTalkTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1373fb;
}
@media screen and (max-width: 1279px) {
  .questionTextStyle {
    max-width: 620px;
  }
}
@media screen and (max-width: 1023px) {
  .containerStyle {
    padding: 80px 60px;
  }
  .simpleHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .simpleDescriptionTextStyle {
    margin-top: 12px;
  }
  .questionsContainerStyle {
    padding: 0px;
  }
  .questionWrapperStyle {
    margin-bottom: 28px;
  }
  .questionTextwrapperStyle {
    padding: 24px;
  }
  .questionTextStyle {
    max-width: 680px;
  }
}

@media screen and (max-width: 867px) {
  .simpleHeaderTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .simpleDescriptionTextStyle {
    width: 100%;
  }
  .questionWrapperStyle {
    margin-bottom: 32px;
  }
  .questionTextStyle {
    max-width: 496px;
  }
  .containerStyle {
    padding: 80px 32px;
  }
}
@media screen and (max-width: 559px) {
  .simpleHeaderContainerStyle {
    text-align: left;
  }

  .questionTextStyle {
    max-width: 266px;
  }
}
