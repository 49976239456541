.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.subContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  height: calc(100vh - 64px);
  margin: auto;
  background-color: var(--white);
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  text-align: left;
  color: var(--black);
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1336px) {
}
@media screen and (max-width: 1279px) {
}
@media screen and (max-width: 1023px) {
}
@media screen and (max-width: 867px) {
}
@media screen and (max-width: 576px) {
  .headerTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (max-width: 374px) {
  .headerTextStyle {
    font-size: 26px;
    line-height: 30px;
  }
}
