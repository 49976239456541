.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.subContainerStyle {
  display: flex;
  flex-direction: column;
  padding: 160px;
  gap: 120px;
}
.navbarStyle {
  width: 100%;
  height: 64px;
  background-color: var(--bg-primary-color);
}
.posterViewStyle {
  background-image: url('../../assets/images/poster-bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: currentColor;
}
.posterSubViewStyle {
  backdrop-filter: blur(8px);
}
.contentWrapperStyle {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.contentSubWrapperStyle {
  display: flex;
  flex-direction: row;
  max-width: 800px;
}
.titleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 64px;
  color: var(--white);
}
.descTextViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  position: relative;
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  color: var(--light-gray-color);
  margin-left: -80px;
}
.playViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: -40%;
  left: -80px;
  right: unset;
  cursor: pointer;
}
.playTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 64px;
  color: var(--white);
}
.returnSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--primary-color);
  padding: 40px 60px;
}
.returnTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 34px;
  color: var(--white);
}
.returnTextStyle span {
  position: relative;
  cursor: pointer;
}
.returnTextStyle span::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--white);
  bottom: 0px;
  left: 0px;
}
/* return modal styles  */
.returnModalStyle {
  position: fixed;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 454px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.overlayStyle {
  background-color: var(--black-color25);
  backdrop-filter: blur(2px);
  z-index: 1;
}
/* item ddetails style */
.itemOverlayStyle {
  background-color: var(--black-color25);
  backdrop-filter: blur(2px);
  z-index: 1;
}
.itemModalStyle {
  position: fixed;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 454px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.itemContentBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.itemImageStyle {
  width: 100%;
  height: 216px;
  border-radius: 8px;
  overflow: hidden;
}
.itemImageStyle img {
  object-fit: contain;
}
.itemDataWrapperStyle {
  display: flex;
  flex-direction: column;
}
.itemTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
.itemDataTitleStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--medium-gray-color);
}
.itemDataDescStyle {
  color: var(--desc-color);
}
.itemDataDescNoteStyle {
  color: var(--pink-color);
}
.btnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 12px;
}
.btnStyle {
  height: 48px;
  box-shadow: none;
  border: none;
  flex: 1;
  text-wrap: nowrap;
  width: 100%;
}

.guestModalTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
}
.guestModalDescStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--medium-gray-color);
}
.errorStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--pink-color);
}
.guestAuthWrapperStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.linkStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  color: var(--primary-color);
}

@media screen and (max-width: 1440px) {
  /* return modal styles  */
  .modalDescTextStyle {
    font-size: 16px;
  }
  .labelTextStyle {
    font-size: 14px;
  }
  .inputStyle {
    font-size: 16px;
  }
  .scanQrTextStyle {
    font-size: 16px;
  }
  .scanQrLinkStyle {
    font-size: 16px;
  }
}
@media screen and (max-width: 1279px) {
  .subContainerStyle {
    padding: 80px;
    gap: 100px;
  }
}
@media screen and (max-width: 1023px) {
  .contentWrapperStyle {
    height: 940px;
  }
  .contentSubWrapperStyle {
    max-width: 452px;
    flex-direction: column;
    gap: 24px;
  }
  .subContainerStyle {
    padding: 80px 60px;
    gap: 80px;
  }
  .titleTextStyle {
    font-size: 40px;
    line-height: 52px;
  }
  .descTextViewStyle {
    gap: 24px;
  }
  .descTextStyle {
    margin-left: 50px;
    font-size: 16px;
    line-height: 22px;
  }
  .playViewStyle {
    position: relative;
    bottom: 0;
    left: 0;
    margin-left: 50px;
  }
  /* return modal styles  */
  .headerTextStyle {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 867px) {
  .returnSectionStyle {
    padding: 40px 32px;
  }
  .returnTextStyle {
    font-size: 24px;
  }
  .subContainerStyle {
    padding: 80px 32px;
    gap: 80px;
  }
}
@media screen and (max-width: 576px) {
  .contentWrapperStyle {
    height: calc(100vh - 56px);
  }
  .contentSubWrapperStyle {
    width: 100%;
    padding: 0px 24px;
  }
  .subContainerStyle {
    padding: 64px 24px;
    gap: 34px;
  }
  .titleTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .descTextStyle {
    font-family: 'Euclid-Circular-B-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .descTextStyle,
  .playViewStyle {
    margin-left: 20px;
  }
  .returnSectionStyle {
    padding: 32px 24px;
    align-items: flex-start;
  }
  .returnTextStyle {
    font-size: 24px;
  }
  .returnTextStyle span {
    text-wrap: nowrap;
  }
  /* return modal styles  */
  .returnModalStyle {
    display: flex;
    height: calc(100% - 64px);
    max-width: 100%;
    width: 100%;
    bottom: 0px !important;
    position: fixed !important;
    border-radius: 16px 16px 0px 0px;
    gap: 16px;
    top: unset;
    transform: none;
    left: unset;
  }
  .headerWrapperStyle {
    border-bottom: 1px solid var(--light-gray-color);
    padding: 16px;
  }
  .modalDescTextStyle {
    padding: 0px 16px;
  }
  .inputWrapperStyle {
    padding: 0px 16px;
  }
  .scanQrTextStyle {
    padding: 0px;
  }
  .itemModalStyle {
    max-width: 100%;
    position: fixed !important;
    bottom: 0 !important;
    top: unset;
    left: unset;
    transform: none;
    border-radius: 16px 16px 0px 0px;
  }
}
@media screen and (max-width: 374px) {
  .contentSubWrapperStyle {
    padding: 0px 20px;
  }
  .titleTextStyle {
    font-size: 30px;
    line-height: 38px;
  }
  .descTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .returnSectionStyle {
    padding: 24px 20px;
  }
  .returnTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  /* return modal styles  */
  .returnModalStyle {
    gap: 12px;
  }
  .headerWrapperStyle {
    padding: 12px;
  }
  .headerTextStyle {
    font-size: 18px;
    line-height: 28px;
  }
  .modalDescTextStyle {
    padding: 0px 12px;
    font-size: 14px;
    line-height: 22px;
  }
  .inputWrapperStyle {
    padding: 0px 12px;
  }
  .labelTextStyle {
    font-size: 12px;
    line-height: 22px;
  }
  .inputStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .scanQrTextStyle,
  .scanQrLinkStyle {
    font-size: 14px;
    line-height: 22px;
  }
}
