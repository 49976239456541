@font-face {
  font-family: 'Euclid-Circular-B-Bold';
  src: url('./assets/fonts/Euclid-Circular-B-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Euclid-Circular-B-SemiBold';
  src: url('./assets/fonts/Euclid-Circular-B-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Euclid-Circular-B-Medium';
  src: url('./assets/fonts/Euclid-Circular-B-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Euclid-Circular-B-Regular';
  src: url('./assets/fonts/Euclid-Circular-B-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Euclid-Circular-B-Light';
  src: url('./assets/fonts/Euclid-Circular-B-Light.ttf');
  font-weight: 300;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* colors */
@import url('./assets/css/theme-colors.css');
