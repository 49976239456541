.cartContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.cartTopSectionStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.headerSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid var(--border-color);
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--black);
}
.closeIconStyle {
  cursor: pointer;
}
/* items style start */
.itemsWrapperStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  gap: 16px;
  overflow-x: scroll;
}
.eachItemViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.eachItemSubWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.itemImgContainerStyle {
  width: 108px;
  height: 108px;
  border-radius: 10px;
  background-color: var(--bg-secondary-color);
  padding: 16px;
  flex-shrink: 0;
}
.itemDetailsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.itemTitleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--black);
}
.itemPriceTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--black-color80);
}
.itemPriceTextStyle span {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--black-color80);
}
.itemImgStyle {
  width: 100px;
  height: 100px;
}
.itemDetailsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.itemDetailsLeftSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.countBtnsStyle {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background-color: var(--bg-secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgStyle {
  width: 24px;
  height: 24px;
}
.countTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: var(--dark-gray-color);
  padding: 0px 9px;
}
.deleteBtnsStyle {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background-color: var(--pink-shadow-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* items style end */
/* button section style */
.btnSectionStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;
  border-top: 1px solid var(--border-color);
}
.btnNoteStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--dark-gray-color);
}
.btnStyle {
  width: 100%;
}
.emptyCartTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-gray-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media screen and (max-width: 1440px) {
  .headerTextStyle {
    font-size: 16px;
  }
  .itemImgContainerStyle {
    width: 96px;
    height: 96px;
  }
  .itemImgStyle {
    width: 90px;
    height: 90px;
  }
  .itemTitleTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .itemPriceTextStyle,
  .btnNoteStyle {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 576px) {
  .itemImgContainerStyle {
    width: 92px;
    height: 92px;
  }
  .itemImgStyle {
    width: 80px;
    height: 80px;
  }
}
