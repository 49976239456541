.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.subContainerStyle {
  margin: auto;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 64px 160px 100px 160px;
  background: var(--white);
}
/* banner section styles */
.bannerSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.bannerTextWrapperStyle {
  display: grid;
  grid-template-columns: 42% 50%;
  gap: 80px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  text-align: left;
  color: var(--black);
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Light';
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: var(--desc-color);
}
.subHeaderTextStyle {
  max-width: 738px;
  margin: auto;
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  color: var(--medium-gray-color);
  text-align: center;
}
.subHeaderBoldTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  color: var(--desc-color);
}
.bannerListItemsWrapperStyle {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}
.listItemWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.itemHeaderTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  color: var(--desc-color);
}
.itemDescTextStyle {
  font-family: 'Euclid-Circular-B-Light';
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: var(--desc-color);
}
/* app store section styles */
.appStoreWrapperStyle {
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 32px;
}
/* subscription section styles */
.subscriptionSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.subscriptionTextWrapperStyle {
  max-width: 738px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.subscriptionHeaderStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  color: var(--black);
  text-align: center;
}
.subscriptionDescStyle {
  font-family: 'Euclid-Circular-B-Light';
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: var(--desc-color);
}
.subscriptionCardsWrapperStyle {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.cardWrapperStyle {
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.tagsPlanWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.planTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.planPriceTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
}
.planTimeTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.checkListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.itemsWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.checkIconWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 16px;
}
.checkIconStyle {
  width: 16px;
  height: 16px;
}
.textStyle {
  font-family: 'Euclid-Circular-B-Light';
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}
/* problems section styles */
.pinkCardWrapperStyle {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--pink-shadow-color);
  border-radius: 16px;
}
.pinkIconWrapperstyle {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1336px) {
  .subContainerStyle {
    padding: 64px 128px 100px 128px;
  }
}
@media screen and (max-width: 1279px) {
  .subContainerStyle {
    padding: 64px 80px 100px 80px;
  }
}
@media screen and (max-width: 1023px) {
  .subContainerStyle {
    padding: 56px 60px 80px 60px;
    gap: 80px;
  }
  /* banner section styles */
  .bannerTextWrapperStyle {
    max-width: 560px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .headerTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .subHeaderTextStyle {
    max-width: 668px;
    font-size: 36px;
    line-height: 44px;
  }
  .subHeaderBoldTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .bannerListItemsWrapperStyle {
    grid-template-columns: repeat(2, 1fr);
  }
  .listItemWrapperStyle {
    gap: 12px;
  }
  .itemHeaderTextStyle,
  .subscriptionHeaderStyle {
    font-size: 36px;
    line-height: 44px;
  }
  /* app store section styles */
  .appStoreWrapperStyle {
    gap: 24px;
  }
  /* subscription section styles */
  .subscriptionSectionStyle {
    gap: 56px;
  }
  .subscriptionTextWrapperStyle {
    max-width: 668px;
    gap: 12px;
  }
  .subscriptionCardsWrapperStyle {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  .planPriceTextStyle {
    font-size: 26px;
    line-height: 34px;
  }
}
@media screen and (max-width: 867px) {
  .subContainerStyle {
    padding: 48px 32px 80px 32px;
  }
  /* banner section styles */
  .headerTextStyle {
    font-size: 30px;
    line-height: 40px;
  }
  .subHeaderTextStyle {
    max-width: 604px;
    font-size: 30px;
    line-height: 40px;
  }
  .subHeaderBoldTextStyle {
    font-size: 30px;
    line-height: 40px;
  }

  .itemHeaderTextStyle,
  .subscriptionHeaderStyle {
    font-size: 30px;
    line-height: 40px;
  }
  /* subscription section styles */
  .subscriptionSectionStyle {
    gap: 48px;
  }
  .subscriptionTextWrapperStyle {
    max-width: 604px;
  }
  .subscriptionCardsWrapperStyle {
    width: 452px;
    margin: auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
  .planPriceTextStyle {
    font-size: 24px;
    line-height: 34px;
  }
}
@media screen and (max-width: 576px) {
  .subContainerStyle {
    padding: 32px 24px 64px 24px;
    gap: 64px;
  }
  /* banner section styles */
  .subHeaderTextStyle {
    max-width: 366px;
    font-size: 26px;
    line-height: 36px;
    text-align: start;
  }
  .subHeaderBoldTextStyle {
    font-size: 26px;
    line-height: 36px;
  }
  .bannerListItemsWrapperStyle {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .listItemWrapperStyle {
    gap: 0px;
  }
  .itemHeaderTextStyle,
  .subscriptionHeaderStyle {
    font-size: 30px;
    line-height: 40px;
  }
  /* app store section styles */
  .appStoreWrapperStyle {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  /* subscription section styles */
  .subscriptionSectionStyle {
    gap: 32px;
  }
  .subscriptionTextWrapperStyle {
    align-items: flex-start;
  }
  .subscriptionHeaderStyle {
    text-align: start;
  }
  .subscriptionDescStyle {
    text-align: start;
  }
  .subscriptionCardsWrapperStyle {
    width: 100%;
    margin: auto;
  }
  .tagsPlanWrapperStyle {
    gap: 0px;
  }
}
@media screen and (max-width: 374px) {
  .subContainerStyle {
    padding: 28px 20px 56px 20px;
    gap: 56px;
  }
  /* banner section styles */
  .bannerTextWrapperStyle {
    gap: 6px;
  }
  .headerTextStyle,
  .itemHeaderTextStyle,
  .subscriptionHeaderStyle {
    font-size: 26px;
    line-height: 36px;
  }
  .descTextStyle,
  .itemDescTextStyle,
  .subscriptionDescStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .subHeaderTextStyle,
  .subHeaderBoldTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .bannerListItemsWrapperStyle {
    gap: 20px;
  }
  /* subscription section styles */
  .subscriptionSectionStyle {
    gap: 28px;
  }
  .cardWrapperStyle {
    padding: 20px;
    gap: 20px;
  }
  .planTextStyle {
    font-size: 12px;
    line-height: 20px;
  }
  .planPriceTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .checkListWrapperStyle {
    gap: 12px;
  }
  .checkIconWrapperStyle {
    width: 22px;
    height: 22px;
    border-radius: 16px;
  }
  .textStyle {
    font-size: 14px;
    line-height: 22px;
  }
}
