.containerStyle {
  display: flex;
  flex-direction: column;
  max-width: 558px;
  width: 100%;
  border-radius: 16px;
  background-color: var(--white);
  height: fit-content;
  max-height: 100%;
}
.notificationScrollStyle {
  height: 100%;
  overflow: scroll;
}
.headerWrapperStyle {
  padding: 16px 24px;
  border-bottom: 1px solid var(--light-gray-color);
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--black);
}
.emptyListWrapperStyle {
  padding: 16px 24px;
}
.boldEmptyTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: var(--black);
}
.boldTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  color: var(--black);
}
.normalEmptyTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: var(--dark-gray-color);
}
.normalTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--dark-gray-color);
}
.notificationWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
  border-bottom: 1px solid var(--light-gray-color);
}
.notificationWrapperStyle:last-child {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
  border-bottom: none;
}
.messageWrapperStyle {
  display: flex;
  flex-direction: column;
}
.listStyle {
  list-style-position: inside;
  color: var(--primary-color);
}
.itemStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--primary-color);
}
.btnsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.buttonStyle {
  padding: 10px 20px;
  background-color: var(--bg-primary-color);
  box-shadow: none;
  color: var(--black);
}
.iconStyle {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 1440px) {
  .headerTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .boldTextStyle,
  .normalTextStyle,
  .normalEmptyTextStyle,
  .boldEmptyTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .notificationWrapperStyle {
    gap: 8px;
  }
  .notificationWrapperStyle:last-child {
    gap: 8px;
  }
  .itemStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .buttonStyle {
    padding: 8px 16px;
  }
  .iconStyle {
    width: 22px;
    height: 22px;
  }
}
@media screen and (max-width: 1336px) {
}
@media screen and (max-width: 1279px) {
}
@media screen and (max-width: 1023px) {
}
@media screen and (max-width: 867px) {
  .containerStyle {
    max-height: calc(100% - 68px);
  }
}
@media screen and (max-width: 576px) {
  .containerStyle {
    border-radius: unset;
  }

  .notificationWrapperStyle {
    gap: 16px;
  }
  .notificationWrapperStyle:last-child {
    gap: 16px;
  }
}
@media screen and (max-width: 374px) {
  .headerTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .boldTextStyle,
  .normalTextStyle,
  .normalEmptyTextStyle,
  .boldEmptyTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .notificationWrapperStyle {
    gap: 12px;
  }
  .notificationWrapperStyle:last-child {
    gap: 12px;
  }
  .itemStyle {
    font-size: 12px;
    line-height: 20px;
  }

  .iconStyle {
    width: 20px;
    height: 20px;
  }
}
