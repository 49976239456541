.inputWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* position: relative; */
  align-items: flex-start;
}
.inputSubWrapperStyle {
  width: 100%;
  position: relative;
}
.inputLabelStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--medium-gray-color);
}
.inputStyle {
  width: 100%;
  height: 44px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px 16px;
  color: var(--black);
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
}
.errorStyle {
  background-color: var(--pink-shadow-color);
  border: 1px solid var(--pink-color);
}
.textAreaStyle {
  height: auto;
}
.inputStyle::placeholder {
  color: #a0a0a0;
}
.inputStyle:active,
.inputStyle:focus {
  outline: none;
}
.inputErrorStyle {
  color: var(--pink-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.leftIconStyle {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
}
.rightIconStyle {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
}
.inputWrapperStyle:has(.leftIconStyle) .inputStyle {
  padding-left: 40px;
}
.inputWrapperStyle:has(.rightIconStyle) .inputStyle {
  padding-right: 40px;
}
.inputStyle {
  input[type='number']::-webkit-inner-spin-button,
  inputStyle .input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
