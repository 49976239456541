.returnWrapperStyle {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;
  width: 100%;
  height: 100%;
}
.modalHeaderWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.headerWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0px 24px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  color: var(--black);
}
.modalDescTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: var(--dark-gray-color);
  padding: 0px 24px;
}
.modalDescTextStyle span {
  color: var(--primary-color);
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-weight: 500;
  cursor: pointer;
}
.inputWrapperStyle {
  padding: 0px 24px;
}
.labelTextStyle {
  font-size: 15px;
  line-height: 24px;
}
.inputStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.scanQrTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: var(--dark-gray-color);
  padding: 0px 24px 24px 24px;
}
.scanQrLinkStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 1023px) {
  .headerTextStyle {
    font-size: 24px;
  }
  .modalDescTextStyle,
  .scanQrTextStyle {
    font-size: 16px;
  }
  .labelTextStyle {
    font-size: 14px;
  }
}
@media screen and (max-width: 867px) {
  .headerTextStyle {
    font-size: 22px;
  }
  .labelTextStyle {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .headerWrapperStyle {
    padding: 16px 24px;
    border-bottom: 1px solid var(--light-gray-color);
  }
}
@media screen and (max-width: 374px) {
  .headerTextStyle {
    font-size: 18px;
  }
  .modalDescTextStyle,
  .scanQrTextStyle {
    font-size: 14px;
  }
  .labelTextStyle {
    font-size: 12px;
  }
}
