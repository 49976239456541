.containerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  gap: 16px;
}
.profileWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0px 24px;
}
.profileSubWrapperStyle {
  /* width: 90%; */
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.userDetailsSectionStyle {
  display: flex;
  flex-direction: column;
}
.profileIconStyle {
  width: 44px;
  height: 44px;
}
.userTitleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: var(--black);
  margin-bottom: 2px;
}
.userDescTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--charcoal-grey);
}
.btnContainerStyle {
  width: fit-content;
  padding: 0px 24px;
}
.btnStyle {
  width: 100%;
  border-radius: 24px;
}
.menuOptionsWrapperStyle {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
}
.eachOptionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 12px;
  cursor: pointer;
}
.activeOptionStyle {
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}
.optionleftStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.menuOptionIconStyle {
  width: 24px;
  height: 24px;
}
.menuOptionRightIconStyle {
  width: 20px;
  height: 20px;
}
.menuOptionTitleStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray-color);
}
@media screen and (max-width: 559px) {
  .containerStyle {
    /* padding: 24px; */
    gap: 12px;
  }
  .btnContainerStyle {
    width: 100%;
  }
  .btnStyle {
    width: 100%;
  }
}
@media screen and (max-width: 374px) {
  .containerStyle {
    /* padding: 20px; */
    gap: 10px;
  }
  .menuOptionIconStyle {
    width: 22px;
    height: 22px;
  }
  .menuOptionRightIconStyle {
    width: 16px;
    height: 16px;
  }
  .menuOptionTitleStyle {
    font-size: 14px;
    line-height: 22px;
  }
}
