.mainPartnerContainerStyle {
  background-color: #ffffff;
}
.partnerContainerStyle {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  gap: 64px;
}
.partnerDescTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 34px;
  text-align: center;
  color: var(--dark-gray-color);
}
.partnerImgContainerStyle {
  max-width: 782px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.imgWrapperStyle {
  background-color: #ebebeb;
  align-items: center;
  justify-content: center;
  border-radius: 84px;
  width: 250px;
  height: 100px;
  /* padding: 24px 30px; */
}
.imgStyle {
  width: 80px;
  height: 32px;
  object-fit: contain;
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1279px) {
  .partnerContainerStyle {
    padding: 100px 80px;
  }
}

@media screen and (max-width: 1023px) {
  .partnerContainerStyle {
    padding: 80px 60px;
  }
}
@media screen and (max-width: 867px) {
  .partnerContainerStyle {
    padding: 80px 32px;
  }
  .imgWrapperStyle {
    width: 224px;
    height: 84px;
  }
}

@media screen and (max-width: 576px) {
  .partnerContainerStyle {
    padding: 80px 24px;
  }
  .partnerImgContainerStyle {
    max-width: 366px;
  }
  .partnerDescTextStyle {
    text-align: left;
  }
  .imgWrapperStyle {
    width: 100%;
  }
}
