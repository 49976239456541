.layoutStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: var(--bg-secondary-color);
  justify-content: center;
}
.contenWrappertStyle {
  max-width: 1440px;
  width: 100%;
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  justify-content: center;
  padding: 40px 80px;
  gap: 16px;
}
.customBgStyle {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px;
  gap: 16px;
  background-color: var(--white);
}
.leftContentWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.menuIconViewStyle {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: var(--white);
  box-shadow: 2px 4px 8px 0px var(--black-color02);
  cursor: pointer;
  position: relative;
}
.iconStyle {
  width: 24px;
  height: 24px;
}
.profileModalStyle {
  width: 320px;
  height: auto;
  top: -45px !important;
  box-shadow: 4px 8px 24px 0px var(--black-color08);
}
.profileWrapperStyle {
  display: flex;
  flex-direction: column;
}
.closeIconViewStyle {
  display: none;
}
.closeIconStyle {
  width: 24px;
  height: 24px;
}

.signInUpBlockStyle {
  display: flex;
  flex-direction: column;
}
.signInUpHeaderStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid var(--off-white-color);
}
.signInUpHeaderTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.signInUpBodyStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  align-items: center;
}
.signInUpBodyIconStyle {
  width: 64px;
  height: 64px;
}
.signInUpTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--black);
}
.signInUpBtnWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.signInUpBtnStyle {
  border-radius: 20px;
  height: 40px;
  flex: 1;
  width: 100%;
}
@media screen and (max-width: 867px) {
  .contenWrappertStyle,
  .customBgStyle {
    padding: 8px 32px 32px 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .leftContentWrapperStyle {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .closeIconViewStyle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0px 32px 0px 32px;
    border-bottom: 1px solid var(--border-color);
  }
  .profileModalStyle {
    width: 100%;
    /* top: 0px !important; */
    border-radius: 0px 0px 16px 16px;
  }
}
@media screen and (max-width: 576px) {
  .contenWrappertStyle,
  .customBgStyle {
    padding: 0px;
    gap: 0px;
  }
  .leftContentWrapperStyle {
    padding: 8px 24px;
  }
  .menuIconViewStyle {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 374px) {
  .leftContentWrapperStyle {
    padding: 8px 20px;
  }
}
