:root,
[data-theme='light'] {
  --white: #fff;
  --black: #000;
  --black-color02: rgba(0, 0, 0, 0.02);
  --black-color50: rgba(0, 0, 0, 0.5);
  --black-color80: rgba(0, 0, 0, 0.8);
  --black-color25: rgba(0, 0, 0, 0.25);
  --black-color08: rgba(0, 0, 0, 0.08);
  --primary-color: #1373fb;
  --bg-primary-color: rgba(232, 241, 255, 1);
  --bg-secondary-color: rgb(242, 242, 242);
  --desc-color: rgba(32, 32, 32, 1);
  --border-color: rgba(208, 208, 208, 1);
  --dark-gray-color: rgba(80, 80, 80, 1);
  --off-white-color: rgb(235, 235, 235);
  --light-gray-color: rgba(226, 226, 226, 1);
  --pink-color: rgba(250, 19, 99, 1);
  --pink-shadow-color: rgba(250, 19, 99, 0.08);
  --medium-gray-color: rgba(128, 128, 128, 1);
  --pale-blue-color: rgba(219, 226, 236, 1);
  --snow-color: rgba(250, 250, 250, 1);
  --white-shade-color: rgba(17, 17, 17, 0.08);
  --lavender-color: rgba(246, 229, 246, 1);
  --light-mint-green-color: rgba(233, 246, 229, 1);
  --peach-color: rgba(255, 241, 230, 1);
  --pale-lavender-color: rgba(243, 233, 255, 1);
  --pale-blue-color12: rgba(232, 241, 255, 0.12);
}
[data-theme='dark'] {
  --white: #fff;
  --black: #000;
  --black-color02: rgba(0, 0, 0, 0.02);
  --black-color50: rgba(0, 0, 0, 0.5);
  --black-color80: rgba(0, 0, 0, 0.8);
  --primary-color: #1373fb;
  --bg-primary-color: rgba(232, 241, 255, 1);
  --bg-secondary-color: rgba(242, 242, 242, 1);
  --desc-color: rgba(32, 32, 32, 1);
  --border-color: rgba(208, 208, 208, 1);
  --dark-gray-color: rgba(80, 80, 80, 1);
  --off-white-color: rgb(235, 235, 235);
  --light-gray-color: rgba(226, 226, 226, 1);
  --pink-color: rgba(250, 19, 99, 1);
  --pink-shadow-color: rgba(250, 19, 99, 0.08);
  --medium-gray-color: rgba(128, 128, 128, 1);
  --pale-blue-color: rgba(219, 226, 236, 1);
  --snow-color: rgba(250, 250, 250, 1);
  --white-shade-color: rgba(17, 17, 17, 0.08);
  --lavender-color: rgba(246, 229, 246, 1);
  --light-mint-green-color: rgba(233, 246, 229, 1);
  --peach-color: rgba(255, 241, 230, 1);
  --pale-lavender-color: rgba(243, 233, 255, 1);
  --pale-blue-color12: rgba(232, 241, 255, 0.12);
}
