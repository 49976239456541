.containerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointerSyle {
  cursor: pointer;
}
.imgStyle {
  width: 100%;
  height: 100%;
}
