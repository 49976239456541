/* empty Section Style start  */
.emptySectionStyle {
  display: flex;
  flex-direction: column;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px;
  gap: 24px;
}
.emptySectionHeaderStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: var(--black);
}
.emptySectionSubWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.documentIconStyle {
  width: 96px;
  height: 96px;
}
.emptySectionDescTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--dark-gray-color);
}

/* verify sno modal style   */
.verifySnoModalStyle {
  background-color: var(--white);
  top: 42px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 454px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
}
.overlayStyle {
  background-color: var(--black-color25);
  backdrop-filter: blur(2px);
  z-index: 1;
}

/* add item modal styles */
.addItemModalStyle {
  max-width: 566px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

/* left section style start  */
.leftSectionStyle {
  max-width: 390px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  overflow: hidden;
  flex-shrink: 0;
}
.leftSectionHeaderBlockStyle {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}
.leftSectionHeaderTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
.filterModalStyle {
  width: 160px;
  border-radius: 8px;
  border: 1px solid var(--off-white-color);
  box-shadow: 4px 8px 24px 0px rgba(0, 0, 0, 0.08);
  top: -26px !important;
  padding: 8px;
}
.leftSectionBodyBlockStyle {
  width: 100%;
  height: calc(100% - 64px);
  overflow-y: scroll;
  padding: 0px 4px 8px 4px;
}
.eachItemStyle {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
.selectedItemStyle {
  background-color: var(--pale-blue-color);
  border-radius: 8px;
}
.itemCategoryIconStyle {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: var(--bg-secondary-color);
  padding: 16px;
  flex-shrink: 0;
}
.itemDataBlockStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.itemTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 17px;
  line-height: 26px;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;
}
.itemDescStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  white-space: nowrap;
}
.itemDescStyle span {
  color: var(--medium-gray-color);
}
/* left section style end */
/* right section style start  */
.rightSectionStyle {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}
.itemImgSectionStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 144px;
  position: relative;
}
.itemImgStyle {
  width: 100%;
  height: 100%;
}
.imgStyle {
  object-fit: cover;
}
.rightHeaderBtnsWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 24px 24px 0px 24px;
}
.rightHeaderbtnStyle {
  width: 40px;
  height: 40px;
  background-color: rgba(17, 17, 17, 0.56);
  border-radius: 100%;
  position: relative;
}
.rightHeaderbtnStyle:last-child {
  margin-left: auto;
}
.iconStyle {
  width: 18px;
  height: 18px;
}
.optionsModalStyle {
  width: 216px;
  border-radius: 8px;
  border: 1px solid var(--off-white-color);
  box-shadow: 4px 8px 24px 0px rgba(0, 0, 0, 0.08);
  top: -26px !important;
  padding: 8px;
}
.eachOptionStyle {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  gap: 8px;
  border-radius: 8px;
}
.eachOptionStyle:hover {
  background-color: var(--bg-primary-color);
}
.optionTitleStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-gray-color);
}
.itemWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid var(--border-color);
}
.labelTextStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: var(--medium-gray-color);
}
.itemDetailsWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}
.itemDataStyle {
  display: flex;
  flex-direction: column;
}
.titleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  color: var(--black);
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--medium-gray-color);
}
.descTextStyle span {
  color: var(--desc-color);
}
.itemNoteViewStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  background-color: var(--bg-secondary-color);
  border-radius: 8px;
}
.itemOtherWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.itemOtherSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-secondary-color);
  padding: 16px;
  border-radius: 8px;
}
.messageTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--dark-gray-color);
  padding: 0px 16px;
}
.archiveSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;
}
.archiveSubSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.arciveTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  color: var(--primary-color);
}

/* empty section style  */
.emptyItemContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
}
.emptyContainerTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--dark-gray-color);
  text-align: center;
  max-width: 54%;
}
/* right section style end  */
/* delete modal style */
.deleteModalStyle {
  max-width: 390px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.deleteModalOverlayStyle {
  background-color: var(--black-color25);
  backdrop-filter: blur(2px);
  z-index: 1;
}
.deleteModalContentStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.deleteModalTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 24px;
  line-height: 34px;
  color: var(--black);
}
.deleteModalDescStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--dark-gray-color);
}
.deleteModalBtnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 12px;
}
.cancelBtnStyle,
.proceedBtnStyle {
  width: 100%;
  border-radius: 20px;
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  box-shadow: none;
  border: none;
}
.proceedBtnStyle {
  background-color: var(--pink-color);
  color: var(--white);
}
.errorTextStyle {
  color: var(--pink-color);
}
@media screen and (max-width: 1440px) {
  .emptySectionHeaderStyle,
  .leftSectionHeaderTitleStyle {
    font-size: 20px;
  }
  .emptySectionDescTextStyle {
    font-size: 16px;
  }
  .emptyContainerTextStyle {
    max-width: 64%;
  }
  .itemTitleStyle {
    font-size: 16px;
  }
}
@media screen and (max-width: 1366px) {
  .emptyContainerTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .leftSectionHeaderTitleStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .leftSectionBodyBlockStyle {
    height: calc(100% - 62px);
  }
  .deleteModalStyle {
    max-width: 360px;
  }
  .deleteModalTitleStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .deleteModalDescStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .documentIconStyle {
    width: 88px;
    height: 88px;
  }
  .emptyContainerTextStyle {
    max-width: 74%;
  }
  .leftSectionStyle {
    max-width: 360px;
  }
}
@media screen and (max-width: 1023px) {
  .emptyContainerTextStyle {
    max-width: 84%;
  }
  .leftSectionStyle {
    max-width: 320px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 20px;
  }
  .itemCategoryIconStyle {
    width: 48px;
    height: 48px;
  }
  .itemTitleStyle {
    font-size: 15px;
  }
}
@media screen and (max-width: 867px) {
  .leftSectionStyle {
    max-width: 100%;
    border-radius: 0px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 32px;
  }
  .leftSectionBodyBlockStyle {
    padding: 0 8px 8px;
  }
  .eachItemStyle {
    padding: 8px 24px;
  }
  .eachItemStyle:not(:last-child) {
    border-bottom: 1px solid var(--light-gray-color);
  }
  .itemTitleStyle {
    font-size: 16px;
  }
  .rightSectionStyle {
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  }
}
@media screen and (max-width: 576px) {
  .emptySectionStyle {
    max-width: 100%;
    height: 100%;
    padding: 16px 24px;
  }
  .emptySectionHeaderStyle {
    font-size: 24px;
  }
  .emptySectionSubWrapperStyle {
    gap: 16px;
  }
  .documentIconStyle {
    width: 80px;
    height: 80px;
  }
  .verifySnoModalStyle {
    display: flex;
    height: calc(100% - 64px);
    max-width: 100%;
    width: 100%;
    bottom: 0px !important;
    position: fixed !important;
    border-radius: 16px 16px 0px 0px;
    gap: 16px;
    top: unset;
    transform: none;
    left: unset;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 20px;
  }
  .leftSectionBodyBlockStyle {
    padding: 0 4px 10px;
  }
  .eachItemStyle {
    padding: 12px 16px;
  }
  .deleteModalStyle {
    max-width: 100%;
    position: fixed;
    bottom: 0 !important;
    transform: none;
    left: unset;
    top: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
@media screen and (max-width: 374px) {
  .emptySectionHeaderStyle,
  .leftSectionHeaderTitleStyle {
    font-size: 18px;
  }
  .emptySectionDescTextStyle {
    font-size: 14px;
  }
  .documentIconStyle {
    width: 72px;
    height: 72px;
  }
  .itemTitleStyle {
    font-size: 14px;
  }
}
