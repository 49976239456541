.signupContainerStyle {
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  position: relative;
}
.statusBarOneStyle {
  width: 100%;
  height: 4px;
  background-color: var(--primary-color);
}
.subContainerSyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  gap: 120px;
  padding: 24px 100px;
}
.headerSectionStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.closeIconStyle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.backTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--dark-gray-color);
}
.formWrapperStyle {
  width: 100%;
}
.formSubWrapperStyle {
  max-width: 452px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.formTopSecctionStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.titleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 26px;
  line-height: 34px;
  color: var(--black);
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--desc-color);
  text-align: center;
}
.descTextStyle span {
  color: var(--primary-color);
  position: relative;
  cursor: pointer;
}
.descTextStyle span::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
  bottom: -2px;
  left: 0;
}
.inputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.forgetTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color);
  text-align: right;
  cursor: pointer;
}
.buttonContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.btnStyle {
  width: 100%;
  border-radius: 8px;
}
.editEmailTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}
.authErrorTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--pink-color);
}
@media screen and (max-width: 1023px) {
  .subContainerSyle {
    gap: 120px;
    padding: 24px 48px;
  }
}
@media screen and (max-width: 867px) {
  .subContainerSyle {
    padding: 24px 32px;
  }

  .formSubWrapperStyle {
    padding: 0px 24px;
  }
}
@media screen and (max-width: 576px) {
  .formSubWrapperStyle {
    padding: 0px;
  }
}
@media screen and (max-width: 360px) {
  .subContainerSyle {
    padding: 24px;
  }
}
