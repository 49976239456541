.posterViewStyle {
  background-image: url('../../assets/images/sustainabilityBgImg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: currentColor;
}
.posterSubViewStyle {
  backdrop-filter: blur(8px);
}
.contentWrapperStyle {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.contentSubWrapperStyle {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
}
.titleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 64px;
  color: var(--white);
}
.descTextViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  position: relative;
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  color: var(--light-gray-color);
  margin-left: -80px;
}
.playViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: -40%;
  left: -80px;
  right: unset;
  cursor: pointer;
}

.playTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 64px;
  color: var(--white);
}

@media screen and (max-width: 1440px) {
  .contentSubWrapperStyle {
    max-width: 800px;
  }
}
@media screen and (max-width: 1023px) {
  /* .sustainabilityBlurContainerStyle {
    padding: 120px 244px;
  } */
  .contentSubWrapperStyle {
    max-width: 452px;
    flex-direction: column;
    gap: 24px;
  }
  .titleTextStyle {
    font-size: 40px;
    line-height: 52px;
  }
  .descTextViewStyle {
    gap: 18px;
  }
  .descTextStyle {
    margin-left: 50px;
    font-size: 16px;
    line-height: 22px;
  }
  .playViewStyle {
    position: relative;
    bottom: 0;
    left: 0;
    margin-left: 50px;
  }
}
@media screen and (max-width: 576px) {
  .contentWrapperStyle {
    height: calc(100vh - 56px);
  }
  .contentSubWrapperStyle {
    width: 100%;
    padding: 0px 24px;
  }
  .titleTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .descTextStyle {
    font-family: 'Euclid-Circular-B-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .descTextStyle,
  .playViewStyle {
    margin-left: 20px;
  }
}
