.containerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid var(--light-gray-color);
  background-color: var(--snow-color);
}
.labelStyle {
  font-family: "Euclid-Circular-B-Medium";
  color: var(--medium-gray-color);
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  text-wrap: nowrap;
}
.pointerStyle {
  cursor: pointer;
}
