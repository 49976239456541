.containerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  position: relative;
}
.emptyChatsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
}
.emptyContainerTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--dark-gray-color);
  text-align: center;
  max-width: 85%;
}
.leftSectionStyle {
  max-width: 390px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  overflow: hidden;
  flex-shrink: 0;
}
.leftSectionHeaderBlockStyle {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}
.leftSectionHeaderTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
.filterBtnStyle {
  padding: 2px 10px;
  box-shadow: none;
  border-radius: 24px;
  border: none;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  gap: 5px;
  position: relative;
}
.filterModalStyle {
  width: 160px;
  border-radius: 8px;
  border: 1px solid var(--off-white-color);
  box-shadow: 4px 8px 24px 0px rgba(0, 0, 0, 0.08);
  top: -26px !important;
  padding: 8px;
}
.chatFilterItemStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
}
.chatFilterItemTitleStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-gray-color);
}
.selectedChatFilterItemTitleStyle {
  color: var(--primary-color);
}
.chatFilterIconStyle {
  width: 20px;
  height: 20px;
}
.leftSectionBodyBlockStyle {
  width: 100%;
  height: calc(100% - 64px);
  overflow-y: scroll;
  padding: 0px 4px 8px 4px;
}
.chatItemStyle {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
.selectedChatItemStyle {
  background-color: var(--pale-blue-color);
  border-radius: 8px;
}
.chatItemIconStyle {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: var(--bg-secondary-color);
  padding: 16px;
  flex-shrink: 0;
}
.chatItemDataBlockStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.chatItemDataWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.chatItemTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 17px;
  line-height: 26px;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;
}
.chaItemDescStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 14px;
  line-height: 22px;
  color: rgba(170, 170, 170, 1);
  white-space: nowrap;
}
.chatItemSubTitleStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 15px;
  line-height: 24px;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 235px;
}
.chatItemSubDescStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 15px;
  line-height: 24px;
  color: var(--medium-gray-color);
}
.chatItemCountStyle {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--white);
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightSectionStyle {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--white);
  overflow: hidden;
  position: relative;
}
.rightSectionHeaderBlockStyle {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  background-color: var(--pale-blue-color);
}
.chatDataIconStyle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--bg-secondary-color);
  padding: 12px;
  flex-shrink: 0;
}
.chatDataContentWrapperStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.chatDataContentTitleStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
.chatDataContentDescStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 15px;
  line-height: 24px;
  color: var(--dark-gray-color);
}
.chatDataActionIconStyle {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
}
.chatOptionModalStyle {
  border-radius: 8px;
  border: 1px solid var(--off-white-color);
  box-shadow: 4px 8px 24px 0px var(--black-color08);
  top: -26px !important;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.chatOptionStyle {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
}
.chatOptionIconStyle {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.chatOptionTextStyle {
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray-color);
}
.selectedChatOptionTextStyle {
  color: var(--primary-color);
}
.messagesBodyBlockStyle {
  width: 100%;
  height: calc(100% - 142px);
  padding: 16px 24px;
}
.emptyMessagesContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.messageListStyle {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.dateWrapperStyle {
  margin: auto;
}
.leftTextWrapperStyle,
.rightTextWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}
.rightTextWrapperStyle {
  flex-direction: row-reverse;
}
.rightTextWrapperStyle + .rightTextWrapperStyle,
.leftTextWrapperStyle + .leftTextWrapperStyle {
  margin-top: 4px;
}
.leftTextWrapperStyle + .rightTextWrapperStyle,
.rightTextWrapperStyle + .leftTextWrapperStyle,
.dateWrapperStyle + .leftTextWrapperStyle,
.dateWrapperStyle + .rightTextWrapperStyle,
.leftTextWrapperStyle + .dateWrapperStyle,
.rightTextWrapperStyle + .dateWrapperStyle {
  margin-top: 16px;
}
.messageTextStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 272px;
  width: fit-content;
  padding: 8px 16px;
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 16px;
  line-height: 24px;
  color: var(--desc-color);
  word-wrap: break-word;
  text-wrap: wrap;
}
.messageImageStyle {
  border-radius: 12px;
  overflow: hidden;
}
.leftTextWrapperStyle .messageTextStyle {
  border-radius: 12px 12px 12px 0;
  background-color: var(--bg-secondary-color);
  color: var(--desc-color);
}
.rightTextWrapperStyle .messageTextStyle {
  border-radius: 12px 12px 0 12px;
  background-color: var(--primary-color);
  color: var(--white);
}
.messageTimeStyle {
  font-family: 'Euclid-Circular-B-Medium';
  font-size: 12px;
  line-height: 18px;
  color: var(--medium-gray-color);
}
.messageInputWrapperStyle {
  position: absolute;
  padding: 0 24px;
  bottom: 24px;
  width: 100%;
}
.messageInputStyle {
  width: 100%;
  border-radius: 32px;
  background-color: var(--snow-color);
  border: 1px solid var(--bg-secondary-color);
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-family: 'Euclid-Circular-B-Regular';
  font-size: 17px;
  line-height: 26px;
  color: var(--desc-color);
}
.inputLeftIconStyle {
  transform: translateY(-50%);
}
.inputRightIconStyle {
  transform: translateY(-50%);
}

@media screen and (max-width: 1366px) {
  .emptyContainerTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .leftSectionHeaderTitleStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .filterBtnStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .leftSectionBodyBlockStyle {
    height: calc(100% - 62px);
  }
  .chatItemIconStyle {
    width: 56px;
    height: 56px;
  }
  .chatItemTitleStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .chaItemDescStyle {
    font-size: 12px;
    line-height: 18px;
  }
  .chatItemSubTitleStyle,
  .chatItemSubDescStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .chatItemCountStyle {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 18px;
  }

  .chatDataIconStyle {
    width: 48px;
    height: 48px;
  }
  .chatDataContentTitleStyle,
  .chatDataContentDescStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .chatDataActionIconStyle {
    width: 24px;
    height: 24px;
  }
  .messagesBodyBlockStyle {
    padding: 12px 24px;
    height: calc(100% - 140px);
  }
  .messageInputStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1279px) {
  .filterBtnStyle {
    gap: 4px;
  }
  .leftSectionStyle {
    max-width: 360px;
  }
  .chatItemTitleStyle {
    width: 180px;
  }
  .chatItemSubTitleStyle {
    width: 200px;
  }
}
@media screen and (max-width: 1023px) {
  .leftSectionStyle {
    max-width: 320px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 20px;
  }
  .chatItemIconStyle {
    width: 48px;
    height: 48px;
  }
  .chatItemTitleStyle {
    font-size: 15px;
    line-height: 22px;
    width: 140px;
  }
  .chatItemSubTitleStyle {
    width: 160px;
  }

  .rightSectionHeaderBlockStyle {
    padding: 10px 20px;
  }
  .messagesBodyBlockStyle {
    padding: 10px 20px;
    height: calc(100% - 130px);
  }
  .messageTextStyle {
    font-size: 15px;
    line-height: 22px;
  }
  .messageInputWrapperStyle {
    padding: 0 20px;
    bottom: 20px;
  }
  .messageInputStyle {
    height: 42px;
    font-size: 15px;
    line-height: 22px;
  }
}
@media screen and (max-width: 867px) {
  .leftSectionStyle {
    max-width: 100%;
    border-radius: 0px;
  }
  .leftSectionHeaderBlockStyle {
    padding: 16px 32px;
  }
  .leftSectionBodyBlockStyle {
    padding: 0 8px 8px;
  }
  .chatItemStyle {
    padding: 8px 24px;
  }
  .chatItemStyle:not(:last-child) {
    border-bottom: 1px solid var(--light-gray-color);
  }
  .chatItemIconStyle {
    width: 56px;
    height: 56px;
  }
  .chatItemTitleStyle {
    font-size: 16px;
    line-height: 24px;
    max-width: 330px;
    width: 100%;
  }
  .chatItemSubTitleStyle {
    max-width: 330px;
    width: 100%;
  }
  .rightSectionStyle {
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  }

  .rightSectionHeaderBlockStyle {
    padding: 12px 24px;
  }
  .messagesBodyBlockStyle {
    padding: 12px 24px;
    height: calc(100% - 140px);
  }
  .messageTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .messageInputWrapperStyle {
    padding: 0 24px;
    bottom: 24px;
  }
  .messageInputStyle {
    height: 44px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 576px) {
  .leftSectionHeaderBlockStyle {
    padding: 16px 20px;
  }
  .leftSectionBodyBlockStyle {
    padding: 0 4px 10px;
  }
  .chatItemStyle {
    padding: 12px 16px;
  }
  .chatItemTitleStyle {
    max-width: 186px;
    width: 100%;
  }
  .chatItemSubTitleStyle {
    max-width: 186px;
    width: 100%;
  }

  .rightSectionHeaderBlockStyle {
    padding: 12px 20px;
  }
  .messagesBodyBlockStyle {
    padding: 10px 20px;
    height: calc(100% - 136px);
  }
  .messageInputWrapperStyle {
    padding: 0 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 374px) {
  .leftSectionHeaderTitleStyle {
    font-size: 18px;
    line-height: 28px;
  }
  .chatItemIconStyle {
    width: 48px;
    height: 48px;
  }
  .chatItemTitleStyle {
    font-size: 14px;
    line-height: 22px;
  }

  .rightSectionHeaderBlockStyle {
    padding: 10px 20px;
  }
  .leftTextWrapperStyle,
  .rightTextWrapperStyle {
    gap: 6px;
  }
  .messageTextStyle {
    font-size: 14px;
    line-height: 22px;
  }
  .messagesBodyBlockStyle {
    height: calc(100% - 130px);
  }
  .messageInputStyle {
    height: 42px;
  }
}
