.checkoutPageMainContainerStyle {
  max-width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  overflow-y: scroll;
}
.closeContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: var(--white);
  padding: 16px 0px;
  z-index: 1;
}
.closeIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.checkoutTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
}
.checkOutGridContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 64px;
}
.checkOutPageInsideContainerStyle {
  min-width: 604px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.checkoutPageInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.checkoutHeaderContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-secondary-color);
  padding: 24px;
  border-radius: 16px;
}
.checkIconWrapperStyle {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: rgb(196, 196, 196);
}
.completedStepViewStyle {
  background-color: var(--primary-color);
}
.checkOutHeaderInsideContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.contactInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contactDetailsInputContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.contactDetailsTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}
.orderSummeryStyle {
  width: 100%;
  height: fit-content;
  border: 1px solid var(--border-color);
  border-radius: 16px;
}
.orderedProductsSummeryContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  width: 100%;
}
.orderSummeryTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--desc-color);
  text-align: left;
}
.noOfOrderedProductsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.orderProductContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.orderProductImgWrapperStyle {
  width: 106px;
  height: 106px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
  padding: 4px;
}
.orderedProductRightContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orderProductPricesContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.orderProductTagsTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  text-align: left;
}
.orderProductPriceTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--desc-color);
  text-align: left;
}
.orderProductDetailsContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.addMinusContainerSTyle {
  display: flex;
  flex-direction: row;
  gap: 13px;
  align-items: center;
}
.productNumberStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--desc-color);
  text-align: center;
  align-self: center;
}
.addIconWrapperStyle {
  width: 32px;
  height: 32px;
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--off-white-color);
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
}
.iconWrapperStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dividerStyle {
  width: 100%;
  border-bottom: 1px solid var(--off-white-color);
}
.costAndPayContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.costListContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.productPriceContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fillAddressTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: var(--dark-gray-color);
}
.priceSubHeadTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: var(--desc-color);
}
.costTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
.totalPriceContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.totalTextStyle {
  display: flex;
  flex-direction: column;
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  align-items: flex-start;
}
.totalPriceTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}
.withoutShippingtextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: var(--dark-gray-color);
}
.payBtnStyle {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 12px;
  border-radius: 28px;
  font-size: 17px;
  line-height: 24px;
  width: 100%;
}
/* modal Container Styles */
.modalContainerStyle {
  max-width: 588px;
  margin: auto auto auto auto;
  padding: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: var(--white);
}
.orderPlacedImgWrapperStyle {
  width: 123px;
  height: 120px;
}
.modalInsideContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.textContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.modalHeadertextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}
.modalDescTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--desc-color);
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.trackOrderBtnStyle {
  width: 100%;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 12px 0;
  border-radius: 28px;
  font-size: 17px;
  line-height: 24px;
}
.doitLaterBtnStyle {
  width: 100%;
  border: 1px solid var(--black);
  background-color: var(--white);
  color: var(--black);
  padding: 12px 0;
  border-radius: 28px;
  font-size: 17px;
  line-height: 24px;
}
.downArrowStyle {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.itemImgContainerStyle {
  width: 108px;
  height: 108px;
  border-radius: 10px;
  background-color: var(--bg-secondary-color);
  padding: 16px;
  flex-shrink: 0;
}
.itemImgStyle {
  width: 100px;
  height: 100px;
}

/* modal Container Styles end */
@media screen and (max-width: 1440px) {
  .fillAddressTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
  .priceSubHeadTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .withoutShippingtextStyle {
    font-family: 'Euclid-Circular-B-Light', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-gray-color);
  }
  .payBtnStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
  .checkOutPageInsideContainerStyle {
    min-width: 500px;
  }
  .checkOutGridContainerStyle {
    gap: 45px;
  }
}

@media screen and (max-width: 1023px) {
  .checkoutPageMainContainerStyle {
    gap: 57px;
    width: 100%;
  }
  .checkOutGridContainerStyle {
    flex-direction: column;
    gap: 56px;
    margin: auto;
    width: 100%;
  }
  .checkOutPageInsideContainerStyle {
    width: 100%;
  }
  .orderedProductsSummeryContainerStyle {
    gap: 24px;
    padding: 24px;
  }
}
@media screen and (max-width: 867px) {
  .checkoutPageMainContainerStyle {
    padding: 0px 32px 32px;
    gap: 49px;
  }
  .checkOutGridContainerStyle {
    gap: 48px;
    min-width: 100%;
  }
  .checkOutPageInsideContainerStyle {
    gap: 32px;
    min-width: 100%;
    width: 100%;
  }
  .checkoutPageInputContainerStyle {
    gap: 24px;
  }
  .checkoutHeaderContainerStyle {
    padding: 16px;
  }
  .contactInputContainerStyle {
    gap: 16px;
  }
}

@media screen and (max-width: 576px) {
  .checkoutPageMainContainerStyle {
    padding: 0px 24px 40px;
    gap: 33px;
    width: 100%;
  }
  .checkOutGridContainerStyle {
    /* max-width: 100%; */
    gap: 32px;
  }
  .contactDetailsInputContainerStyle {
    flex-direction: column;
  }
  .orderSummeryStyle {
    /* min-width: 100%; */
  }
  .modalContainerStyle {
    padding: 24px;
    gap: 24px;
    border-radius: 16px 16px 0 0;
  }
  .orderPlacedImgWrapperStyle {
    /* width: 103px; */
    /* height: 100px; */
  }
}
