.trackStyle {
  width: 100%;
  height: 4px;
  display: flex;
  background-color: var(--border-color);
  border-radius: 20px;
  overflow: hidden;
}
.barStyle {
  background-color: var(--primary-color);
}
