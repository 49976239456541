.containerStyle {
  display: flex;
  flex-direction: column;
  padding: 100px;
  background-color: var(--bg-secondary-color);
}
.simpleHeaderContainerStyle {
  align-items: center;
  justify-content: center;
}
.simpleHeaderTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: var(--black);
  text-align: center;
}
.simpleDescriptionTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--black);
  margin-top: 16px;
  text-align: center;
}
.productsMainContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
  margin-top: 64px;
}
.productContainerStyle {
  background-color: var(--white);
  padding: 32px;
  border-radius: 16px;
  transform-origin: top;
  transition: 0.4s ease-in-out;
}
.productContainerStyle:hover {
  transform-origin: top;
  transform: scaleY(1.05);
}

.productContainerStyle:hover .productImgWrapperStyle {
  transform: scale(1.1);
}
.productImgWrapperStyle {
  max-width: 298.67px;
  max-height: 224px;
  border-radius: 16px;
  transition: ease-in 0.25s;
}

.productNameTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--desc-color);
  margin-top: 32px;
  text-align: center;
}
.priceViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.productPriceStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--desc-color);
  margin-top: 8px;
  text-align: center;
  padding: 8px 20px;
  background-color: var(--bg-secondary-color);
  border-radius: 20px;
  width: max-content;
}
.browseAllProductsAnchorStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 64px;
}
.browseAllProductsStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 3px;
  cursor: pointer;
}
.imgContainerStyle {
  width: 280px;
  height: 280px;
}
@media screen and (max-width: 1366px) {
  .simpleDescriptionTextStyle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}
@media screen and (max-width: 1279px) {
  .imgContainerStyle {
    width: 210px;
    height: 200px;
  }
}
@media screen and (max-width: 1023px) {
  .containerStyle {
    padding: 60px 20px;
  }
  .simpleHeaderTextStyle {
    font-size: 36px;
    line-height: 44px;
  }
  .simpleDescriptionTextStyle {
    margin-top: 12px;
  }
  .productsMainContainerStyle {
    gap: 12px;
    margin-top: 56px;
  }
  .productNameTextStyle {
    margin-top: 24px;
  }
  .productContainerStyle {
    padding: 24px;
  }
  .productImgWrapperStyle {
    max-width: 217.33px;
    max-height: 192px;
  }
  .browseAllProductsAnchorStyle {
    margin-top: 56px;
  }
  .imgContainerStyle {
    width: 200px;
    height: 180px;
  }
}
@media screen and (max-width: 867px) {
  .productsMainContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 48px;
  }
  .productImgWrapperStyle {
    max-width: 404px;
    max-height: 248px;
  }
  .browseAllProductsAnchorStyle {
    margin-top: 48px;
  }
  .imgContainerStyle {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 576px) {
  .simpleHeaderContainerStyle {
    text-align: left;
  }
  .productsMainContainerStyle {
    margin-top: 32px;
  }
  .productImgWrapperStyle {
    max-width: 318px;
    max-height: 248px;
  }
  .browseAllProductsAnchorStyle {
    margin-top: 32px;
  }
}
