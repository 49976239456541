.overlayStyle {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.containerStyle {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: 1;
  border-radius: 8px;
  cursor: initial;
}
