.containerStyle {
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--white);
  border-radius: 16px;
  position: relative;
}
.textWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.titleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 26px;
  line-height: 34px;
  color: var(--black);
}
.subTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
.descStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray-color);
}
.descWrapperStyle {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}
.qrTitleWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
.qrLinkTitleStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  text-decoration: none;
  position: relative;
}
.qrLinkTitleStyle::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  border: 1px solid;
}

.itemModalStyle {
  max-width: 484px;
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.itemContentBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.itemImageStyle {
  width: 100%;
  height: 216px;
  border-radius: 8px;
  overflow: hidden;
}
.itemImageStyle img{
  object-fit: contain;
}
.itemDataWrapperStyle {
  display: flex;
  flex-direction: column;
}
.itemDataTitleStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: var(--medium-gray-color);
}
.itemDataDescStyle {
  color: var(--desc-color);
}
.itemDataDescNoteStyle {
  color: var(--pink-color);
}
.btnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 12px;
}
.btnStyle {
  height: 48px;
  box-shadow: none;
  border: none;
  flex: 1;
  text-wrap: nowrap;
  width: 100%;
}

/* add item modal styles */
.addItemModalStyle {
  max-width: 566px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.addItemOverlayStyle {
  background-color: var(--black-color25);
  backdrop-filter: blur(2px);
  z-index: 1;
}

.customLoaderStyle {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 867px) {
  .containerStyle {
    max-width: 390px;
  }
  .itemModalStyle {
    top: 65px;
  }
  .addItemModalStyle {
    top: 65px;
    max-width: 480px;
  }
  .itemOverlayStyle {
    background-color: var(--black-color25);
    backdrop-filter: blur(2px);
    z-index: 1;
  }
}

@media screen and (max-width: 576px) {
  .containerStyle {
    max-width: 100%;
    border-radius: 0px;
    height: 100%;
  }
  .itemModalStyle {
    max-width: 100%;
    position: fixed !important;
    bottom: 0 !important;
    top: unset;
    left: unset;
    transform: none;
    border-radius: 16px 16px 0px 0px;
  }
  .addItemModalStyle {
    max-width: 100%;
    height: calc(100% - 64px);
    position: fixed !important;
    bottom: 0 !important;
    top: unset;
    left: unset;
    transform: none;
    border-radius: 16px 16px 0px 0px;
  }
}

@media screen and (max-width: 374px) {
}
