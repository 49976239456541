.containerStyle {
  border: 1px solid var(--light-gray-color);
  width: fit-content;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 24px;
  background-color: var(--snow-color);
}
.labelStyle {
  font-family: 'Euclid-Circular-B-Medium';
  color: var(--medium-gray-color);
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  text-wrap: nowrap;
}
.leftIconStyle {
}
.rightIconStyle {
}
