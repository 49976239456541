.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
}
.subContainerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.headerViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0px 24px;
  gap: 12px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  color: var(--black);
}
.headerRightWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.snoViewStyle {
  border-radius: 24px;
  gap: 6px;
  background-color: var(--bg-secondary-color);
  border: none;
}
.snoTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: var(--dark-gray-color);
}
.closeIconStyle {
  cursor: pointer;
}
.tabSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 16px 24px;
  border-bottom: 1px solid var(--border-color);
}
.tabTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--dark-gray-color);
  cursor: pointer;
}
.activeTabStyle {
  font-family: 'Euclid-Circular-B-Medium';
  color: var(--black);
  font-weight: 500;
  position: relative;
}
.activeTabStyle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--black);
  bottom: -16px;
  left: 0;
  border-radius: 1px;
}
.contentViewStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
/* category section start */
.categoryViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--medium-gray-color);
}
.categoriesViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}
.eachCategoryViewStyle {
  padding: 8px 12px;
  border-radius: 8px;
  gap: 6px;
  background-color: var(--snow-color);
  border: 1px solid var(--light-gray-color);
}
.selectedCatStyle {
  background-color: var(--pale-blue-color);
  border: 1px solid var(--pale-blue-color);
}
.selectedCatTextStyle {
  color: var(--black);
}
.categoryTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--dark-gray-color);
}
/* category section end */
.btnsContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0px 24px 24px 24px;
}
.btnsStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.cancelBtnStyle {
  border: 1px solid var(--light-gray-color);
  box-shadow: none;
}
.errorTextStyle {
  color: var(--pink-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--pink-color);
}
/* image upload section start */
.uploadImageContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  padding: 48px 24px;
  gap: 16px;
}
.imageUploadSubViewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageUploadTitelTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: var(--black);
}
.imageUploadDescTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--medium-gray-color);
}
.uploadBtnStyle {
  background-color: var(--bg-primary-color);
  box-shadow: 0px 4px 16px 0px var(--pale-blue-color12);
  color: var(--black);
}
.imageContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  padding: 16px;
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--bg-secondary-color);
}
.imgSubContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.imgDetailsStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.imgViewStyle {
  width: 56px;
  height: 56px;
  border-radius: 8px;
}
.imgTitleAndSizeViewStyle {
  display: flex;
  flex-direction: column;
}
.imgTitleTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--black);
}
.imgSizeTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--medium-gray-color);
}

/* note secctioon style  */
.noteSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.noteExamplesViewStyle {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.noteInputStyle {
  resize: none;
}
.eachNoteViewStyle {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--snow-color);
  border: 1px solid var(--light-gray-color);
  padding: 8px 12px;
  border-radius: 8px;
}
.activeNoteStyle {
  background-color: var(--pale-blue-color);
  border: 1px solid var(--pale-blue-color);
}
.sampleNoteTextStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-gray-color);
}
/* preview Secction style  */
.previewHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 24px 24px 0px 24px;
}
.itemDetailsStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}
.itemImageStyle {
  width: 100%;
  height: 216px;
  border-radius: 8px;
  overflow: hidden;
}
.itemImageStyle img {
  object-fit: contain;
}
.itemDetailsSubWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
.otherDetailsViewStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.itemDetailsLableStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--medium-gray-color);
}
.itemDetailsLableStyle span {
  color: var(--black);
}

@media screen and (max-width: 1279px) {
  .imageUploadTitelTextStyle {
    font-size: 16px;
  }
  .imageUploadDescTextStyle {
    font-size: 14px;
  }
  .uploadBtnStyle {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .containerStyle {
    height: 100%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 374px) {
  .headerViewStyle {
    padding: 20px 20px 0px 20px;
  }
  .headerTextStyle {
    font-size: 18px;
  }
  .snoTextStyle {
    font-size: 12px;
  }
  .snoArrowStyle {
    width: 18px;
    height: 18px;
  }
  .tabSectionStyle {
    padding: 12px 20px;
  }
  .tabTextStyle,
  .labelTextStyle {
    font-size: 12px;
  }
  .categoryTextStyle {
    font-size: 14px;
  }
  .categoryIconStyle {
    width: 22px;
    height: 22px;
  }
  .imageUploadTitelTextStyle {
    font-size: 14px;
  }
  .imageUploadDescTextStyle {
    font-size: 12px;
  }
  .uploadBtnStyle {
    font-size: 13px;
  }
}
