/* scanner section start */
.scannerSectionStyle {
  max-width: 500px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.qrReaderStyle {
  width: 100%;
  height: 100% !important;
  margin: auto;
}
.qrReaderStyle div {
  padding: 0px !important;
  height: 100% !important;
}
.qrReaderStyle section {
  height: 100%;
}
.qrReaderStyle section video {
  object-fit: cover;
}
.qrReaderStyle section div {
  --width: calc(100vw - 250px);
  top: 0px;
  left: 0px;
  z-index: 1;
  box-sizing: border-box;
  /* border: var(--width) solid rgba(0, 0, 0, 0.3); */
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 80px;
  /* outline: 50px solid rgba(0, 0, 0, 0.3); */
  box-shadow: none !important;
  border: none !important;
}
.scannerOptionsViewStyle {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  width: 100%;
}
.scannerOptionsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
}
.scannerIconsViewStyle {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 20px;
  background: var(--black-color50);
  box-shadow: 0px 48px 96px 0px rgba(30, 30, 32, 0.08);
  z-index: 1;
}
.scannerBottomOptionsViewStyle {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.scannerBottomOptionsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
}
.scannerBtnViewStyle {
  height: 40px;
  background: rgba(17, 17, 17, 0.56);
  box-shadow: none;
  padding: 8px 24px;
  border-radius: 20px;
  color: var(--white);
  z-index: 1;
}

.scanBarStyle {
  width: 250px;
  height: 250px;
  outline-offset: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scanBarStyle span::before {
  content: '';
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 5px;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 0 48px 10px #ffffff;
  clip-path: inset(0);
  animation: x 1s ease-in-out infinite alternate, y 2s ease-in-out infinite;
}

.scanBarStyle:before,
.scanBarStyle:after,
.scanBarStyle em:after,
.scanBarStyle em:before {
  border-color: #ffffff;
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-style: solid;
  border-width: 0px;
}

.scanBarStyle:before {
  left: 0;
  top: 0;
  border-left-width: 5px;
  border-top-width: 5px;
  border-radius: 20px 0 0 0;
}

.scanBarStyle:after {
  right: 0;
  top: 0;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 20px 0 0;
}
.scanBarStyle em:before {
  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 20px;
}
.scanBarStyle em:after {
  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 20px 0;
}

@keyframes x {
  to {
    transform: translateY(-100%);
    top: 90%;
  }
}

@keyframes y {
  0% {
    clip-path: inset(0 0 0 0);
  }
  49% {
    clip-path: inset(-100px 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 -100px 0);
  }
}
.scanResultModalStyle {
  top: unset;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  max-width: 390px;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px;
  border: 1px solid var(--off-white-color);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.scanResultTextWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.scanResultTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
.scanResultDescStyle {
  font-family: 'Euclid-Circular-B-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray-color);
}
.scanResultBtnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 16px;
}
/* scanner section end*/
@media screen and (max-width: 867px) {
  .scannerSectionStyle {
    max-width: 100%;
  }
  .itemModalStyle {
    top: 65px;
  }
  .itemOverlayStyle {
    background-color: var(--black-color25);
    backdrop-filter: blur(2px);
    z-index: 1;
  }
}

@media screen and (max-width: 576px) {
  .scanResultModalStyle {
    max-width: 100%;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }
  .itemModalStyle {
    max-width: 100%;
    position: fixed !important;
    bottom: 0 !important;
    top: unset;
    left: unset;
    transform: none;
    border-radius: 16px 16px 0px 0px;
  }
}

@media screen and (max-width: 374px) {
  .scanResultModalStyle {
    padding: 20px;
  }
  .scanResultTextWrapperStyle {
    gap: 6px;
  }
  .scanResultTextStyle {
    font-size: 20px;
    line-height: 30px;
  }
  .scanResultDescStyle {
    font-size: 14px;
    line-height: 22px;
  }
}
