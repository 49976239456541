.containerStyle {
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  margin: auto;
  padding: 24px 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.contentWrapperStyle {
  max-width: 452px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.headerTextStyle {
  font-family: 'Euclid-Circular-B-Medium', sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  color: var(--black);
}
.descTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: var(--desc-color);
}
.btnStyle {
  border-radius: 8px;
  width: 100%;
}
.responseTextStyle {
  font-family: 'Euclid-Circular-B-Light', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--pink-color);
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1336px) {
  .containerStyle {
    padding: 24px 80px;
  }
}
@media screen and (max-width: 1261px) {
  .containerStyle {
    padding: 24px 64px;
  }
}
@media screen and (max-width: 1023px) {
  .containerStyle {
    padding: 24px 48px;
  }
}
@media screen and (max-width: 867px) {
  .containerStyle {
    padding: 24px 32px;
  }
}
@media screen and (max-width: 576px) {
  .containerStyle {
    padding: 24px;
  }
}
@media screen and (max-width: 360px) {
}
