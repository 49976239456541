.containerStyle {
  width: 468px;
  height: 468px;
  border-radius: 8px;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.actionBtnWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}
.closeIconStyle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px;
  cursor: pointer;
}
.doneBtnStyle {
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  box-shadow: none;
}
.imageStyle {
  width: 468px;
  height: calc(468px - 86px);
}
.imageStyle img {
  object-fit: contain;
}
.loaderContainerStyle {
  background-color: #474747;
}
.loaderStyle {
  border: 3px solid var(--black);
  border-top: 3px solid var(--white);
}
